import React from 'react';
import Link from 'next/link';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ShopNow from './ShopNow';

const sliderData = [
  {
    src: 'hero-img-1.png',
    title: '',
    text: 'African businesses are in good company. The bitMARTe platform is built to meet all your needs with ease, scalability, and robustness.',
    cardTitle: 'Original Brown Leather Shoe',
    cardSrc: 'assets/img/brown-shoe.png',
    heroReviewSrc: 'assets/img/hero-review-1.png',
  },
  {
    src: 'hero-img-2.png',
    title: '',
    text: 'African businesses are in good company. The bitMARTe platform is built to meet all your needs with ease, scalability, and robustness.',
    cardTitle: 'TMA-2 Modular Headphone',
    cardSrc: 'assets/img/headphone.png',
    heroReviewSrc: 'assets/img/hero-review-2.png',
  },
  {
    src: 'hero-img-3.png',
    title: '',
    text: 'African businesses are in good company. The bitMARTe platform is built to meet all your needs with ease, scalability, and robustness.',
    cardTitle: 'TMA-2 Modular Headphone',
    cardSrc: 'assets/img/headphone.png',
    heroReviewSrc: 'assets/img/hero-review-3.png',
  },
];

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Hero = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <AutoPlaySwipeableViews
      axis="x"
      index={activeStep}
      onChangeIndex={handleStepChange}
      enableMouseEvents
      interval={10000}
    >
      {sliderData.map((item, index) => (
        <div className="overflow-x-hidden px-6">
          <section className="relative flex flex-col items-center md:h-[85vh] md:flex-row xl:max-h-[640px] ">
            <div className="flex flex-1 flex-col items-center justify-center px-6 py-14 sm:px-14 md:items-start md:py-0 xl:px-0">
              <h1 className="mb-2 text-center text-2xl font-semibold text-[#19191D] sm:text-5xl sm:text-[50px] sm:leading-[70px] md:text-left">
                {' '}
                Grow Your Business <br /> With Us
              </h1>
              <p className="max-w-[380px] text-center text-base font-normal text-[#19191DB2] md:text-left">
                {' '}
                African businesses are in good company. The bitMARTe platform is
                built to meet all your needs with ease, scalability, and
                robustness.
              </p>
              <div className="mt-4 flex items-center gap-2">
                <Link href="/merchant/signup">
                  <a className="rounded-md border border-primary-500 bg-primary-500 px-3 py-1.5 font-normal capitalize text-white hover:bg-transparent hover:text-primary-500">
                    Get started
                  </a>
                </Link>
                <Link href="/customer">
                  <a className="rounded-md border border-primary-500 bg-transparent px-3 py-1.5 font-normal capitalize text-black transition-all">
                    Visit Market Place
                  </a>
                </Link>
              </div>
            </div>
            <div className="relative flex flex-1 items-center justify-center overflow-x-visible px-6 md:px-0 lg:self-end">
              <div className="relative">
                <img
                  src={`assets/img/${item.src}`}
                  alt=""
                  className="relative z-[2]"
                />
                <img
                  src="/assets/img/ellipse-5.png"
                  alt=""
                  className="absolute -top-6 left-1/2 z-[1] hidden -translate-x-1/2 md:block"
                />
              </div>
              <div className="green__gradient absolute top-[-74px] z-[1] hidden h-[150px] w-[150px] rounded-full md:block" />
              <img
                alt="alt"
                src={item.heroReviewSrc}
                className="absolute top-16 -right-14 z-[3] hidden overflow-x-visible md:max-w-[250px] lg:block"
              />
              <ShopNow title={item.cardTitle} src={item.cardSrc} />
            </div>
            <Link href="/customer">
              <img
                alt="alt"
                src="/assets/icons/dangling-hero-image.svg"
                className="swingimage absolute left-1/2 -top-2 hidden h-auto -translate-x-1/2 cursor-pointer md:block"
              />
            </Link>
          </section>
        </div>
      ))}
    </AutoPlaySwipeableViews>
  );
};

export default Hero;

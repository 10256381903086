import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Image from 'next/image';
import Link from 'next/link';
import { data, images } from './data';

const Accordion2 = () => {
  const [expanded, setExpanded] = React.useState<number | false>(0);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(panel);
    };

  return (
    <section className="relative overflow-hidden bg-primary-700 py-10">
      <div className="mx-auto flex w-10/12 flex-col items-center justify-between lg:flex-row">
        <div className="w-full py-20 px-3 text-white lg:w-1/3">
          <h6 className="px-3 pb-5 text-sm font-medium tracking-wider">
            CUSTOMIZABLE E-COMMERCE PLATFORM
          </h6>
          <h2 className="space-x-3 px-3 pb-5 text-2xl font-semibold lg:text-4xl">
            Manage products
          </h2>
          <h4 className="border-b border-b-gray-300 px-3 pb-5 font-light lg:w-full">
            Create and edit your products, prices and variants.
          </h4>
          <div>
            {data.map((item, index) => (
              <Accordion
                key={index}
                expanded={expanded === index}
                className="change-bg border-0 bg-primary-700 pl-0"
                sx={{
                  '&.Mui-expanded': {
                    margin: 0,
                    borderBottom: 'solid 1px rgba(209, 213, 219, 1)',
                  },
                }}
                onChange={handleChange(index)}
                elevation={0}
              >
                <div
                  className={`${
                    expanded === index ? 'border-0' : 'border-b border-gray-300'
                  }`}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon className="text-3xl text-white" />
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={`border-b-4 border-b-primary-400 ${
                      expanded === index ? 'py-0' : 'py-3'
                    } `}
                  >
                    <h4 className="text-base font-medium text-white lg:text-xl">
                      {item.title}
                    </h4>
                  </AccordionSummary>
                  {expanded === index && (
                    <div className="h-[4px] w-full rounded-lg bg-gradient-to-r from-[#e5ec9a] to-[#f2f6cc]" />
                  )}
                </div>
                <AccordionDetails>
                  <p className="w-11/12 py-3 text-sm font-light text-gray-300">
                    {item.text}
                  </p>

                  <Link href={item.link} passHref>
                    <div className="flex cursor-pointer items-center space-x-5">
                      <span className="text-sm font-light text-[#e5ec9a]">
                        Learn More
                      </span>

                      <ArrowRightAltIcon sx={{ color: '#fff' }} />
                    </div>
                  </Link>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
        <div className="lg:pt-15 hidden w-full items-center justify-center md:flex lg:w-2/3">
          {data.map((_, index) =>
            expanded === index ? (
              <figure
                key={index}
                className="relative h-[60vw] w-9/12 animate-fade-up overflow-hidden rounded-lg lg:h-[45vw]  xl:max-h-[750px] xl:max-w-[900px]"
              >
                <Image
                  src={images[index]}
                  alt="image giving a snippet of our E-COMMERCE PLATFORM"
                  layout="fill"
                />
              </figure>
            ) : (
              <></>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Accordion2;

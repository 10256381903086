import { Icon } from '@iconify/react';
import { ProductCardProps } from 'interfaces/landing-page/home/HomeProps';

const ProductCard: React.FC<ProductCardProps> = ({ icon, text, subText }) => {
  return (
    <div className="product-card mx-1 flex h-[130px] flex-col items-center justify-center gap-4 rounded-xl bg-black p-1 text-primary-500 shadow-md transition-all md:h-[170px] md:gap-2 md:px-5 lg:mx-0 lg:hover:bg-primary-500 lg:hover:text-white">
      <Icon icon={icon} className="text-3xl font-medium md:text-5xl" />
      <div className="text-white">
        <h4 className="pb-1 text-xs font-medium lg:text-sm">{text}</h4>
        {subText && <p className="text-sm font-light">{subText}</p>}
      </div>
    </div>
  );
};

export default ProductCard;

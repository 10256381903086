import Image from 'next/image';
import { homeCardData } from './data';

const Cards = () => {
  return (
    <div className="mx-auto flex w-10/12 flex-col gap-12 md:flex-row">
      {homeCardData.map(({ img, title, text }, index) => (
        <div
          key={index}
          className="flex w-full flex-1 flex-col items-center justify-center space-y-1 rounded-md bg-white p-6 md:py-8 md:px-7"
        >
          <figure>
            <Image width={82} height={79} src={img} alt="" />
          </figure>

          <h3 className="text-xl font-medium text-black lg:text-base">
            {title}
          </h3>
          <p className="text-center text-sm text-gray-500">{text}</p>
        </div>
      ))}
    </div>
  );
};

export default Cards;

export const homeAboutRowData = [
  {
    id: 1,
    text: 'Manage your  business while  on the go with bitMARTe. Track inventory and stock, create products, fulfil orders, and overview your business performance with up to date analytics.',
    title: 'Run your Business Without Limits',
  },
  {
    id: 2,
    text: 'Create your own customizable online store. bitMARTe makes it easy to customize and design your store to reflect your brand. with its easy to use customization panel you can codelessly design your store front ',
    title: 'Customize and extend your online presence',
  },
  {
    id: 3,
    text: 'bitMARTe gives merchants the power to add and delete products, manage inventory, process orders, etc. that makes it easy to use and mange online stores without stress.',
    title: 'Manage your store with ease',
  },
];

export const homeCardData = [
  {
    id: 1,
    img: '/assets/img/home-card-illustration-1.png',
    title: 'All You Need to Start',
    text: 'Get started with the onboarding process by signing up with your business details ',
  },
  {
    id: 2,
    img: '/assets/img/home-card-illustration-2.png',
    title: 'Customize and Extend',
    text: 'As a merchant, you can  model your store and product theme to reflect your brand identity.',
  },
  {
    id: 3,
    img: '/assets/img/home-card-illustration-3.png',
    title: 'Active Community',
    text: 'Join our growing community for updates, feedbacks, theme development, and more general conversations about bitMARTe.',
  },
];

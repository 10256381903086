import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';
import data from './data';
import Slider from 'react-slick';

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#BED002',
        borderRadius: '50%',
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#BED002',
        borderRadius: '50%',
      }}
      onClick={onClick}
    />
  );
}

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 2,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

const CustomerReviews = () => {
  const [activeStep, setActiveStep] = useState(0);

  const startTimerWrapper = useCallback((func) => {
    let timeInterval: NodeJS.Timer;
    return () => {
      if (timeInterval) {
        clearInterval(timeInterval);
      }
      setActiveStep(0);
      timeInterval = setInterval(() => {
        func(timeInterval);
      }, 10000);
    };
  }, []);

  const timer = useCallback(
    startTimerWrapper((intervalfn: NodeJS.Timeout) => {
      setActiveStep((val) => {
        if (val === data.length - 1) {
          return 0;
        }
        return val + 1;
      });
    }),
    []
  );

  useEffect(() => {
    timer();
  }, []);

  return (
    <>
      {/* Mobile View */}
      <div className="block md:hidden">
        <div className="flex flex-col items-center gap-12">
          <div className="relative rounded-xl bg-white p-3 text-center text-[#292828] shadow before:absolute before:-bottom-3 before:left-1/2 before:h-6 before:w-6 before:-translate-x-1/2 before:rotate-45 before:bg-white before:shadow before:shadow-white md:p-12">
            <h5 className="mb-4 text-base font-semibold md:text-xl">
              “{data[activeStep].title}”
            </h5>
            <p className="text-xs font-normal md:text-lg">
              {data[activeStep].text}
            </p>
          </div>
          <div className="flex items-center gap-6">
            <Image
              src={data[activeStep].img}
              width={62}
              height={62}
              alt="image of customer in their review"
            />
            <div>
              <p className="mb-2 text-sm font-medium md:text-lg">
                {data[activeStep].name}
              </p>
              <p className="text-xs md:text-sm">{data[activeStep].location}</p>
            </div>
          </div>
          <div className="flex items-center justify-center space-x-5">
            {data.map((_, index) => (
              <div
                role="button"
                key={index}
                className={`h-2 w-2 rounded-full lg:h-3 lg:w-3 ${
                  activeStep === index ? 'bg-gray-500' : 'bg-gray-300'
                }`}
                onClick={() => setActiveStep(index)}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Desktop View */}
      <div className="hidden md:block">
        <Slider {...settings}>
          {data.map((review, index) => (
            <div key={index}>
              <div className="flex flex-col items-center gap-12">
                <div className="relative rounded-xl bg-white p-3 text-center text-[#292828] shadow before:absolute before:-bottom-3 before:left-1/2 before:h-6 before:w-6 before:-translate-x-1/2 before:rotate-45 before:bg-white before:shadow before:shadow-white md:p-12">
                  <h5 className="mb-4 text-base font-semibold md:text-xl">
                    “{review.title}”
                  </h5>
                  <p className="text-xs font-normal text-gray-500 md:text-base">
                    {review.text}
                  </p>
                </div>
                <div className="flex items-center gap-6">
                  <Image
                    src={review.img}
                    width={62}
                    height={62}
                    alt="image of customer in their review"
                  />
                  <div>
                    <p className="mb-2 text-sm font-medium md:text-lg">
                      {review.name}
                    </p>
                    <p className="text-xs md:text-sm">{review.location}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default CustomerReviews;

import type { NextPage } from 'next';
import Head from 'next/head';
import HomeScreen from 'screens/landing-page/home';

const Home: NextPage = () => {
  return (
    <div className="">
      <Head>
        <title>bitMarte</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <HomeScreen />
    </div>
  );
};

export default Home;

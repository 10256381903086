export const data = [
  {
    id: 1,
    text: 'Keep track of your inventory to monitor your stock level, identify when you have too much stock in your inventory and pinpoint when you need to order or make more of your products. Notifications are also sent to let you know when your stock is running low',
    title: 'Manage Inventory',
    link: '',
  },
  {
    id: 2,
    text: 'bitMARTe provides you with customizable themes that helps you reflect your brand. All this will be done with no technical expertise required.',
    title: 'Customize Theme ',
    link: '',
  },
  {
    id: 3,
    text: 'Create discount and coupons codes to drive sales and promotions.You have the ability to set discounts in a variety of ways.',
    title: 'Discount Management',
    link: '',
  },
];

export const images = [
  '/assets/img/screenshot-product-1.svg',
  '/assets/img/screenshot-themes4.jpeg',
  '/assets/img/screenshot-themes.png',
];

import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  contactUs,
  setStatus,
} from 'state/features/contact/contactUsFuncSlice';
import { Backdrop, CircularProgress } from '@mui/material';

const socialIcons = [
  {
    src: '/assets/icons/facebook.svg',
    url: 'https://mobile.facebook.com/bitmarte?eav=AfagcmWzE9spWZD9AVMBFZ5LG4jd0kEjkJpx_cHjzjbPO_wBaonFvaRtA8GPI9M3i7k&paipv=0',
  },
  {
    src: '/assets/icons/instagram.svg',
    url: 'https://www.instagram.com/_bitmarte/',
  },
  {
    src: '/assets/icons/twitter.svg',
    url: 'https://mobile.twitter.com/_bitMarte',
  },
  {
    src: '/assets/icons/youtube.svg',
    url: 'https://www.youtube.com/channel/UCQ63nTiX_868JpaYn1wY0tg',
  },
  {
    src: '/assets/icons/whatsapp.svg',
    url: '',
  },
  {
    src: '/assets/icons/linkedin.svg',
    url: 'https://www.linkedin.com/company/bitmarte/mycompany/',
  },
  {
    src: '/assets/icons/tiktok.svg',
    url: 'https://www.tiktok.com/@_bitmarte?_t=8XZ1jF7RIDV&_r=1',
  },
];

const Newsletter = () => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { status, error } = useAppSelector(
    (state: any) => state.contactUsFuncData
  );
  const dispatch = useAppDispatch();

  const handleContactUs = (e: any, payload: object) => {
    e.preventDefault();
    // console.log('payload',payload)
    if (email.trim() === '') {
      toast.error('Sorry, a mail cannot be sent to an empty field');
    } else {
      setLoading(true);
      dispatch(contactUs({ payload }))
        .then((res: any) => {
          setLoading(false);
          // console.log(res);
          if (!res.payload) {
            toast.error(
              'Something went wrong, please check your internet connection'
            );
          } else {
            toast.success('Your subscription was successfull');
            setEmail('');
          }
        })
        .catch((err: any) => {
          setLoading(false);
          toast.error(
            'Something went wrong, please check your internet connection'
          );
        });
    }
  };

  return (
    <>
      {loading && (
        <Backdrop
          sx={{
            mt: '0px !important',
            height: '100vh',
            width: '100vw',
            zIndex: 5000,
          }}
          open={loading}
        >
          <CircularProgress sx={{ color: '#fff' }} />
        </Backdrop>
      )}
      <div className="hidden w-full bg-black py-16  px-6 lg:block">
        <div className=" relative  mx-auto   grid  grid-cols-3 items-center gap-3 lg:max-w-screen-lg xl:max-w-[1400px] ">
          <div className="mt-7 md:mt-0">
            <p className="text-center text-[16px] text-white md:text-left">
              Subscribe to our newsletter & get exclusive offers
            </p>
            <form
              className="mt-2 grid grid-cols-2"
              onSubmit={(e) => handleContactUs(e, { email })}
            >
              <input
                className="px-2 py-2"
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                type="submit"
                className="h-full bg-[#9CAB01] py-2 px-4 md:text-base"
              >
                Subscribe Now
              </button>
            </form>
          </div>
          <div className="mt-7 ml-12  md:mt-0 ">
            <p className="text-center text-[16px] text-white md:text-left">
              Join Our Community
            </p>
            <div className="mt-2 flex w-full items-center justify-between  ">
              <a
                href="https://mobile.facebook.com/bitmarte?eav=AfagcmWzE9spWZD9AVMBFZ5LG4jd0kEjkJpx_cHjzjbPO_wBaonFvaRtA8GPI9M3i7k&paipv=0"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/icons/fb1.svg" width={30} alt="" />
              </a>

              <a
                href="https://www.instagram.com/_bitmarte/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/icons/instagram.svg" width={30} alt="" />
              </a>
              <a
                href="https://mobile.twitter.com/_bitMarte"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/icons/twitter.svg" width={30} alt="" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCQ63nTiX_868JpaYn1wY0tg"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/icons/youtube.svg" width={30} alt="" />
              </a>
              <a href="#" target="_blank" rel="noreferrer">
                <img src="/assets/icons/whatsapp.svg" width={30} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/bitmarte/mycompany/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/icons/linkedin.svg" width={30} alt="" />
              </a>
              <a
                href="https://www.tiktok.com/@_bitmarte?_t=8XZ1jF7RIDV&_r=1"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/icons/tiktok.svg" width={30} alt="" />
              </a>
            </div>
          </div>
          <div className=" absolute right-0 top-0 flex items-center justify-center">
            {/* <a href="#" target="_blank" rel="noreferrer"> */}
            <img src="/assets/img/googlePlayStore.svg" alt="" />
            {/* </a> */}
            {/* <a href="#" target="_blank" rel="noreferrer"> */}
            <img src="/assets/img/appStore.svg" alt="" />
            {/* </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Newsletter;

import React from 'react';
import Image from 'next/image';
import Button from 'components/shared/Button';
import Link from 'next/link';

const Banner = () => {
  return (
    <section className="w-full">
      <div className="mx-auto flex w-11/12 flex-col items-center justify-center lg:flex-row">
        <div className="pt-20 pb-5 md:w-full lg:w-1/3 lg:py-0">
          <div className="mb-10 border-b-2 border-b-gray-300 py-5">
            <h3 className="text-center text-2xl font-medium text-black md:text-left md:text-3xl lg:text-4xl 2xl:text-5xl">
              Your <span className="text-primary-500">eCommerce</span> made
              simple
            </h3>
            <p className="mx-auto w-3/4 py-3 text-center text-sm leading-7 text-black md:w-full md:text-left md:leading-10 lg:py-5 2xl:text-lg">
              bitMARTe is robust, customizable eCommerce platform built for
              Africa. Get Start quickly and make your way.
            </p>
          </div>
          <Link href="/merchant/signup">
            <Button className="mx-auto border-2 border-primary-500 bg-primary-500 text-white transition-all hover:bg-transparent hover:text-primary-500 md:mx-0">
              Get Started
            </Button>
          </Link>
        </div>
        <div className="relative flex h-[70vw] w-full items-center justify-center lg:h-[45vw] lg:w-1/2 xl:h-[45vw]">
          <figure className="absolute top-[40%] left-1/2 z-10 h-[50vw]  w-[68vw] max-w-[600px] -translate-x-1/2 -translate-y-1/2  lg:top-[50%] lg:h-[30vw] lg:max-h-[none] lg:w-[42vw] lg:max-w-[none]">
            <Image
              layout="fill"
              src="/assets/img/banner-image2.png"
              alt="picture showing eCommerce made simple"
            />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default Banner;

import { Icon } from '@iconify/react';
import Slider from 'react-slick';
import { useRef } from 'react';

const mobileSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 1,
};
const desktopSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 3,
};
const data = [
  {
    icon: 'bi:tools',
    text: 'You can design your storefront to suit your brand. You get to maintain your brand identity whilst still selling on our large marketplace.',
  },
  {
    icon: 'heroicons:user-group-20-solid',
    text: 'You get access to your customers’ data which enables you to communicate with them as you wish.',
  },
  {
    icon: 'ci:notification-dot',
    text: 'You get notified of orders immediately across multiple channels; WhatsApp, emails, and in-app notifications.',
  },
  {
    icon: 'bi:tools',
    text: 'Enjoy the best merchant support. Our team is always ready to assist you anytime.',
  },
  {
    icon: 'heroicons:user-group-20-solid',
    text: 'We provide high value at little cost.',
  },
  {
    icon: 'ci:notification-dot',
    text: ' You make money and sales while sleeping',
  },
];

const Banner2 = () => {
  const sliderRef = useRef<any>();
  const handleNext = () => {
    sliderRef.current.slickNext();
  };
  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <>
      <section className="overflow-x-hidden bg-gradient-to-l from-[#fAfAfA] lg:hidden">
        <div className="relative h-[30vh] w-full bg-[url('/assets/img/woman-smiling.png')] bg-cover bg-center bg-no-repeat md:h-full md:w-2/5">
          <h2 className="absolute top-1/2 right-0 w-[40%] -translate-y-1/2 text-2xl font-semibold text-primary-500">
            Why sell on{' '}
            <span className="font-extrabold">
              <span className="text-black">bit</span>MART
              <span className="text-black">e</span>?
            </span>
          </h2>
        </div>
        <div className="mx-auto w-[95%] py-4">
          <Slider {...mobileSettings}>
            {data.map((item, index) => (
              <div className="px-2">
                <div
                  key={index}
                  className="flex min-h-[160px] flex-col items-center gap-4 rounded-md bg-white py-6 px-4 shadow-md"
                >
                  <div className="flex h-11 w-11 items-center justify-center rounded-full bg-stone-50">
                    <Icon
                      icon={item.icon}
                      className="text-xl text-primary-500"
                    />
                  </div>
                  <p className="text-center text-sm text-gray-500 md:text-sm">
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* Desktop */}
      <section className="hidden h-[55vh] w-full bg-gradient-to-l from-[#fafafa] lg:flex">
        <div className="h-full w-2/5">
          <img
            src="/assets/img/woman-smiling.png"
            alt=""
            className="h-full w-full object-cover"
          />
        </div>
        <div className="flex flex-1 flex-col justify-center overflow-hidden py-4">
          <div className="flex items-start justify-between">
            <h2 className="mb-8 hidden text-2xl font-semibold text-primary-500 md:block md:text-4xl">
              Why sell on{' '}
              <span className="font-extrabold">
                <span className="text-black">bit</span>MART
                <span className="text-black">e</span>?
              </span>
            </h2>
            <div className="mr-4 flex items-center space-x-3">
              <button
                onClick={handlePrevious}
                className="flex h-8 w-8 items-center justify-center rounded-full  bg-white shadow-xl"
              >
                <Icon
                  icon="akar-icons:chevron-left"
                  style={{ fontSize: '15px' }}
                />
              </button>
              <button
                onClick={handleNext}
                className=" flex h-8 w-8 items-center justify-center rounded-full  bg-white shadow-xl"
              >
                <Icon
                  icon="akar-icons:chevron-right"
                  style={{ fontSize: '15px' }}
                />
              </button>
            </div>
          </div>
          <div>
            <Slider ref={sliderRef} {...desktopSettings}>
              {data.map((item, index) => (
                <div className="px-2">
                  <div
                    key={index}
                    className="flex min-h-[170px] flex-col items-center justify-center gap-4 rounded-lg bg-white py-1 px-4 text-black shadow transition-all md:w-full md:gap-3"
                  >
                    <div className="flex h-5 w-5 items-center justify-center rounded-full bg-stone-50 md:h-[40px] md:w-[40px]">
                      <Icon
                        icon={item.icon}
                        className="text-xl text-primary-500"
                      />
                    </div>
                    <p className="text-center text-sm text-gray-500">
                      {item.text}
                    </p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner2;

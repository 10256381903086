import { Icon } from '@iconify/react';
import Link from 'next/link';
import React from 'react';

const ShopNow = ({ title, src }: { title: string; src: string }) => {
  return (
    <div
      className={`absolute -left-20 bottom-28 z-[3] hidden flex-col items-start rounded-md bg-white p-5 lg:flex`}
    >
      <div className="flex items-center gap-4">
        <p className="w-[107px] text-[13px] font-bold capitalize leading-[19.5px]">
          {title}
        </p>
        <img src={src} alt="" className="w-[78px]" />
      </div>
      <Link href="/">
        <a className="flex items-center gap-0.5 text-[10px] text-[#FB8200]">
          <span>Shop Now</span>
          <Icon icon="akar-icons:arrow-right" className="inline-block" />
        </a>
      </Link>
    </div>
  );
};

export default ShopNow;

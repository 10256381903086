import Button from 'components/shared/Button';
import Link from 'next/link';

const GetStarted = () => {
  return (
    <section className="bg-white py-14">
      <div className="mx-auto flex w-10/12 flex-col items-center gap-4">
        <h2 className="text-lg font-medium md:text-2xl">Get Started Today</h2>
        <p className="text-sm md:text-base">
          Join the largest, safest, and most trusted community of online sellers
          in Nigeria
        </p>

        <Link href="/merchant/signup">
          <Button className="border-2 border-primary-500 bg-primary-500 text-sm text-white transition-all hover:bg-transparent hover:text-primary-500 md:text-base">
            Start Selling
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default GetStarted;

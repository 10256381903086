import * as React from 'react';
import { Icon } from '@iconify/react';
import Button from 'components/shared/Button';
import LandingPageLayout from 'layouts/landing/landingPageLayout';
import Hero from 'components/landingPage/Hero';
import Cards from 'components/landingPage/Cards';
import Accordion1 from 'components/landingPage/Accordion1';
import Banner from 'components/landingPage/Banner';
import Accordion2 from 'components/landingPage/Accordion2';
import Testimonial from 'components/landingPage/Testimonial';
import ProductServices from 'components/landingPage/products/ProductServices';
import runOneSignal from 'utils/notificationFuncs/runOneSignal';
import OneSignalReact from 'react-onesignal';
import Image from 'next/image';
import { homeCardData } from 'components/landingPage/Cards/data';
import Banner2 from 'components/landingPage/Banner2';
import CustomerReviews from 'components/landingPage/CustomerReviews';
import GetStarted from 'components/landingPage/GetStarted';
import Newsletter from 'components/landingPage/Newsletter';

const HomeScreen = () => {
  // const run = async () => {
  //   await OneSignalReact.getUserId()
  //   console.log('hello')
  // }

  // React.useEffect(() => {
  //   run

  //   return () => {
  //     run
  //   }
  // }, [])

  // console.log('hello')

  return (
    <LandingPageLayout>
      <div className="hero-bg flex items-start justify-center">
        <div className="w-full xl:max-w-[1180px]">
          <Hero />
        </div>
      </div>

      <section className="w-full bg-[url('/assets/img/dotted-background.png')] bg-cover bg-no-repeat py-12 md:py-24">
        <h2 className="mb-10 text-center text-xl text-white md:text-3xl">
          Getting Started
        </h2>
        <Cards />
      </section>
      <section className="bg-white">
        <div className="mx-auto flex w-11/12 flex-col-reverse items-center justify-between py-14 md:py-7 lg:flex-row 2xl:justify-center">
          <div className="relative h-[400px] w-full overflow-hidden md:h-[720px] lg:w-2/5 lg:overflow-visible">
            <figure className="absolute top-0 left-[-5%] h-full w-full md:left-[-5%] lg:left-[15%] xl:top-0 xl:left-0">
              <Image
                src="/assets/icons/tilted-phones.svg"
                layout="fill"
                alt="phone showing bitmarte store"
              />
            </figure>
          </div>
          <div className="lg:w-1/2">
            <h2 className="mb-8 text-center text-2xl font-medium md:text-left md:text-3xl">
              Some Benefits
            </h2>
            <Accordion1 />
          </div>
        </div>
      </section>
      <Banner />
      <Banner2 />
      <Accordion2 />
      <ProductServices />

      <section className="bg-[#FBFCF0] bg-[url('/assets/img/curves-mobile.png')] bg-left-top bg-no-repeat py-20 lg:bg-[url('/assets/img/curves.png')]">
        <div className="mx-auto w-10/12">
          <h2 className="mb-12 text-center text-lg font-normal md:text-3xl">
            Customers Reviews
          </h2>
          <CustomerReviews />
        </div>
      </section>
      <GetStarted />
      <Newsletter />
    </LandingPageLayout>
  );
};

export default HomeScreen;

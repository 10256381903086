import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { homeAboutRowData } from './data';
import Image from 'next/image';

const Accordion1 = () => {
  const [expanded, setExpanded] = React.useState<string | false>('panel0');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(panel);
    };

  return (
    <div className="flex w-full flex-col">
      {homeAboutRowData.map(({ id, text, title }, index) => (
        <Accordion
          key={index}
          elevation={0}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
        >
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="flex items-center space-x-5">
              <div>
                {expanded === `panel${index}` ? (
                  <div className="relative h-6 w-6 rounded-full border border-primary-500 bg-primary-200 shadow-xl before:absolute before:left-1/2 before:top-1/2 before:h-3 before:w-3 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full before:bg-primary-500 before:content-[''] md:h-12 md:w-12 md:before:h-8 md:before:w-8" />
                ) : (
                  <div className="h-6 w-6 rounded-full border border-primary-500 bg-white md:h-12 md:w-12" />
                )}
              </div>
              <h3 className="ml-5 text-lg font-semibold text-black">{title}</h3>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <p className="text-sm font-light text-[#05050780] md:ml-20 lg:max-w-md">
              {text}
            </p>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default Accordion1;

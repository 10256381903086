const customerReviewsData = [
  {
    title: 'Incredible Experience',
    text: ' We had an incredible experience working with bitMARTe and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly.',
    img: '/assets/img/customer-picture-1.png',
    name: 'Mama Ada',
    location: 'Masha, Surulere',
  },
  {
    title: 'Highly recommend',
    text: 'We had an incredible experience working with bitMARTe and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly.',
    img: '/assets/img/customer-picture-2.png',
    name: 'Adewale Shittu',
    location: 'Ikate, Lekki Phase 1',
  },
  {
    title: 'Incredible Experience',
    text: ' We had an incredible experience working with bitMARTe and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly.',
    img: '/assets/img/customer-picture-1.png',
    name: 'Mama Ada',
    location: 'Masha, Surulere',
  },
  {
    title: 'Highly recommend',
    text: 'We had an incredible experience working with bitMARTe and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly.',
    img: '/assets/img/customer-picture-2.png',
    name: 'Adewale Shittu',
    location: 'Ikate, Lekki Phase 1',
  },
];

export default customerReviewsData;

export const homeCardData = [
  {
    id: 1,
    img: '/assets/icons/start.svg',
    // imgList: ['/assets/img/start_1.png'],
    imgList: [],
    title: 'All You Need to Start',
    text: 'Get started with the onboarding process by signing up with your business details ',
  },
  {
    id: 2,
    img: '/assets/icons/community.svg',
    // imgList: ['/assets/icons/ads.svg','/assets/icons/fb.svg','/assets/icons/jetpack.svg','/assets/icons/mailchimp.svg','/assets/icons/square.svg'],
    title: 'Customize and Extend',
    text: 'As a merchant, you can  model your store and product theme to reflect your brand identity.',
  },
  {
    id: 3,
    img: '/assets/img/active-main.png',
    // imgList: ['/assets/img/active.png','/assets/img/active2.png'],
    title: 'Active Community',
    text: 'Join our growing community for updates, feedbacks, theme development, and more general conversations about bitMARTe. This community is a great place to find answers, to your questions and meet potential buyers and merchants.',
  },
];

export const homeAboutRowData = [
  {
    id: 1,
    text: 'Manage your  business while  on the go with bitMARTe.Track inventory and stock, create products, fulfil orders, and overview your business performance with up to date analytics.',
    title: 'Run your Business Without Limits',
  },
  {
    id: 2,
    text: 'Manage your  business while  on the go with bitMARTe.Track inventory and stock, create products, fulfil orders, and overview your business performance with up to date analytics.',
    title: 'Customize and extend your online presence',
  },
  {
    id: 3,
    text: 'Manage your  business while  on the go with bitMARTe.Track inventory and stock, create products, fulfil orders, and overview your business performance with up to date analytics.',
    title: 'Manage your store with ease',
  },
];

export const homeFeatureData = [
  {
    id: 1,
    panel: 'panel1',
    text: 'Keep track of your inventory to avoid sell more stock than you have, identifying when you have too much stock in your inventory and pinpointing when you need to order or make more of your product. You can also manage inventory in multiple stores and  locations',
    title: 'Manage Inventory',
    link: '/',
  },
  {
    id: 2,
    panel: 'panel2',
    text: 'Keep track of your inventory to avoid sell more stock than you have, identifying when you have too much stock in your inventory and pinpointing when you need to order or make more of your product. You can also manage inventory in multiple stores and  locations',
    title: 'Order Fulfillment',
    link: '/',
  },
  {
    id: 3,
    panel: 'panel3',
    text: 'Keep track of your inventory to avoid sell more stock than you have, identifying when you have too much stock in your inventory and pinpointing when you need to order or make more of your product. You can also manage inventory in multiple stores and  locations',
    title: 'Feature 1',
    link: '/',
  },
  {
    id: 4,
    panel: 'panel4',
    text: 'Keep track of your inventory to avoid sell more stock than you have, identifying when you have too much stock in your inventory and pinpointing when you need to order or make more of your product. You can also manage inventory in multiple stores and  locations',
    title: 'Feature 2',
    link: '/',
  },
];

export const productCardData = [
  {
    icon: 'emojione-monotone:bank',
    text: 'Loans to SMEs',
  },
  {
    icon: 'simple-line-icons:graduation',
    text: 'Business Growth Education',
  },
  {
    icon: 'fluent:handshake-16-regular',
    text: 'Business Intervention',
  },
  {
    icon: 'arcticons:game-plugins',
    text: 'Plugins',
  },
  {
    icon: 'akar-icons:trophy',
    text: 'SME Performance Reward',
  },
  {
    icon: 'carbon:analytics',
    text: 'Business Analytics',
  },
  {
    icon: 'tabler:currency-naira',
    text: 'Business Financial Solution',
  },
  {
    icon: 'fluent:dual-screen-20-regular',
    text: 'Duality',
  },
  {
    icon: 'carbon:cloud-services',
    text: 'Offerings for Service Providers',
  },
  {
    icon: 'carbon:gateway-api',
    text: 'Communication Gateway',
  },
  {
    icon: 'academicons:africarxiv',
    text: 'The African',
    subText: '(coming soon)',
  },
  {
    icon: 'simple-icons:startrek',
    text: 'Startrek',
    subText: '(coming soon)',
  },
];

import { productCardData } from 'utils/landing-page/home/homeData';
import ProductCard from './cards/ProductCard';
import Slider from 'react-slick';

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 2,
};

const ProductServices = () => {
  return (
    <section className="product-services relative w-full py-10 text-center md:py-20 ">
      <div className="relative z-10 mx-auto w-10/12 ">
        <div className="psu"></div>
        <div className="flex flex-col items-center pb-10 ">
          <div className="flex  items-center space-x-3">
            <img
              src="/assets/img/bitMARTe-logo.png"
              alt="bitMARTe logo"
              className="h-[100px]"
            />
            <h3 className="text-center text-xl font-bold leading-normal text-[#19191D] md:text-3xl">
              Entrepreneurial Empowerment
            </h3>
          </div>
        </div>
        {/* Mobile */}
        <div className="lg:hidden">
          <Slider {...settings}>
            {productCardData.map(({ icon, subText, text }, index) => (
              <ProductCard
                icon={icon}
                subText={subText}
                text={text}
                key={index}
              />
            ))}
          </Slider>
        </div>
        {/* Desktop */}
        <div className="hidden grid-cols-2 items-center gap-2 lg:grid lg:grid-cols-3 lg:gap-10 xl:grid-cols-4">
          {productCardData.map(({ icon, subText, text }, index) => (
            <ProductCard
              icon={icon}
              subText={subText}
              text={text}
              key={index}
            />
          ))}
        </div>
      </div>
      {/* <img
          src="/assets/img/watermark.jpeg"
          alt=""
          className="-z-10 h-full"
        /> */}
    </section>
  );
};

export default ProductServices;
